import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { ContentImage } from '../components/content/image';
import { ContentVideo } from '../components/content/video';
import { ContentExternalVideo } from '../components/content/externalVideo';
import { ContentText } from '../components/content/text';
import { ContentFootnote } from '../components/content/footnote';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in"

// eslint-disable-next-line react/prop-types
const Article = ({ data }) => {
  const article = data.datoCmsSingleArticle;

  const buildContent = (obj) => {
    const type = obj.model.name;
    if (type === 'External Video') {
      return <ContentExternalVideo key={obj.id} video={obj.video} caption={obj.workCaption} />;
    }
    if (type === 'Image') {
      return <ContentImage key={obj.id} image={obj.image} caption={obj.workCaption} />;
    }
    if (type === 'Text') {
      return <ContentText key={obj.id} text={obj.text} />;
    }
    if (type === 'Video') {
      return <ContentVideo key={obj.id} video={obj.video.video.mp4Url} caption={obj.workCaption} />;
    }
    if (type === 'Footnotes') {
      return <ContentFootnote key={obj.id} text={obj.text} />;
    }

    return null;
  };

  return (
    <Layout bgcolor={article.backgroundColor} wrapperClass="article-wrapper article-single" accentColor={article.accentColor}>
      <HelmetDatoCms seo={article.seoMetaTags} />
      <div className="article-block">
        <div className="main-title col">
          <h1
            className="serif--large large-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: article.formattedTitle,
            }}
          />
        </div>
        <div className="sidebar col">
          <p className="sidebar-text">{article.articleType.title}</p>
        </div>
        <div className="main-image col">
          <Img
            fluid={{ ...article.headerImage.fluid }}
            alt={article.headerImage.alt}
          />
          {article.headerImage && (
            <p
              className="caption"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: article.headerImage.title,
              }}
            />
          )}
        </div>
        <div className="article-intro row">
          <div className="image-wrap col">
					{article.standfirst !== "" && (
            <h4
              className="sans--regular"
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: article.standfirst,
              }}
            />
					)}
            <span>{`By ${article.author}`}</span>
          </div>
          <div className="intro-divider col">
            <DividerBlock accentColor={article.accentColor} />
          </div>
        </div>
        <div className="article-content serif--body row">
          {article.content.length > 0
          && article.content.map((content) => (
            buildContent(content)
          ))}
        </div>
      </div>
 
      <div className="article-bottom-divider container row">
        <div className="divider col-sm-5 col-xs-4 offset-sm-3 col">
          <DividerBlock accentColor={article.accentColor} />
        </div>
      </div>

      <div className="article-footer">
        <div className="container row">
          <div className="writing-sidebar col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Related content</span>
          </div>
          <div className="content col-sm-12 col-xs-6 tiles tiles-4">

            {/* Exhibition */}
            {article.associated && (
              <div className="exhibition tile col">
                <Link
                to={`/exhibition/${article.associated.slug}`} >
                  <ImageFadeIn
                      src={article.associated.featuredImage.url}
                      alt={article.associated.featuredImage.alt}
                    />
                </Link>
                <div className="image-text-row-text-wrapper">
                  <Link to={`/exhibition/${article.associated.slug}`} >
                    <span>Exhibition</span>
                    <h4
                      className="sans--regular"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: article.associated.formattedTitle,
                    }}
                    />
                  </Link>
                </div>
              </div>
              )
            }
            
            {/* Studio Artists */}
            {article.studioArtists.length > 0 && article.studioArtists.map(
              ({
                index,
                profileImage,
                name,
                slug,
              }) => ( 
                <div className="studio-artist tile col">
                  <Link
                  to={`/artist/${slug}`} >
                    <ImageFadeIn
                        src={profileImage.url}
                        alt={profileImage.alt}
                      />
                  </Link>
                <div className="image-text-row-text-wrapper">
                  <Link to={`/artist/${slug}`} >
                    <span>Studio Artist</span>
                    <h4
                      className="sans--regular"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: name,
                    }}
                    />
                  </Link>
                </div>
              </div>
              )
            )}

            {/* Publications */}
            {article.associatedPublications.length > 0 && article.associatedPublications.map(
              ({
                id,
                formattedTitle,
                slug,
                featuredImage,
                year,
              }) => (
              <div className="publication tile col">
                <Link
                to={`/publications/${slug}`} >
                  <ImageFadeIn
                      src={featuredImage.url}
                      alt={featuredImage.alt}
                    />
                </Link>
                <div className="image-text-row-text-wrapper">
                  <Link to={`/publications/${slug}`} >
                    <span>Publication</span>
                    <h4
                      className="sans--regular"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: formattedTitle,
                    }}
                    />
                  </Link>
                </div>
              </div>
              )
            )}

          </div>
        </div>
      </div>
    </Layout>
  );
};

Article.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Article;

export const query = graphql`
  query ArticleQuery($slug: String!) {
    datoCmsSingleArticle(slug: { eq: $slug }) {
      id
      backgroundColor
      accentColor {
        hex
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      articleType {
        title
      }
      author
      standfirst
      headerImage {
        alt
        title
        fluid(maxHeight: 800) {
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      studioArtists {
        slug
        name
        profileImage {
          url(imgixParams: { w: "450", ar: "1:1", fit: "crop", crop: "focalpoint" })
          alt
        }
        id
      }
      associatedPublications {
        id
        formattedTitle
        slug
        category {
          title
        }
        year
        featuredImage {
          url(imgixParams: { w: "450", ar: "1:1", fit: "crop", crop: "focalpoint" })
          alt
        }
      }
      formattedTitle
      id
      associated {
        id
        slug
        internal {
          type
        }
        formattedTitle
        featuredImage {
          url(imgixParams: { w: "450", ar: "1:1", fit: "crop", crop: "focalpoint" })
          alt
        }
        startDate
        endDate
      }
      content {
        ... on DatoCmsImage {
          id
          model {
            name
          }
          image {
            title
            alt
            fluid(maxHeight: 700) {
              ...GatsbyDatoCmsSizes_noBase64
            }
          }
          workCaption
        }
        ... on DatoCmsText {
          id
          model {
            name
          }
          text
        }
        ... on DatoCmsFootnote {
          id
          model {
            name
          }
          text
        }
        ... on DatoCmsVideo {
          id
          model {
            name
          }
          video {
            video {
              mp4Url
            }
          }
          workCaption
        }
        ... on DatoCmsExternalVideo {
          id
          model {
            name
          }
          video {
						url
						providerUid
						title
					}
          workCaption
        }
      }
    }
  }
`;
